import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { fetchBalance, logout } from 'stores/auth';
import Language from '../../layout/_components/Language';
import { AWS_BUCKET_URL } from '../../toolkit/constants';
import { removeAllBetSlip } from 'stores/betSlip';
import { BsQrCodeScan } from 'react-icons/bs';

export default function Profile(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation('translations');
  const navigate = useNavigate();
  const { onClose } = props;
  const balance = useSelector((state) => state.auth.balance);
  const refundBonusBalance = useSelector((state) => state.auth.refundBonusBalance);
  const user = useSelector((state) => state.auth.user);
  const showQrDeposit = user?.isPrintBarcodeEnabled === true;

  useEffect(() => {
    dispatch(fetchBalance());
  }, []);

  const handleLogout = () => {
    dispatch(logout());
    dispatch(removeAllBetSlip());
    onClose();
    navigate('/');
  };

  return (
    <Container fluid>
      <Card className="mb-3">
        <Card.Body>
          <Row className="align-items-center h6">
            <Col xs={7} className="d-flex align-items-center">
              <div className="me-3">
                <img size={24} src={`${AWS_BUCKET_URL}/Icons/58.png`} />
              </div>
              <div className="">
                <h1 className="display-6 fs-7 fw-bold mb-1">{user.username}</h1>
                <h1 className="display-6 fs-7 text-muted mb-0">{user.email}</h1>
              </div>
            </Col>
            <Col xs={5} className="text-end">
              <div className={`badge h5 text-end bg-${balance > 0 ? 'success' : 'danger'}`}>
                {balance.toLocaleString('de-DE', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }) + ' €'}
              </div>
              <br></br>
              <div
                className={`badge h5 text-end bg-${refundBonusBalance > 0 ? 'primary' : 'danger'}`}>
                {refundBonusBalance.toLocaleString('de-DE', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }) + ' €'}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* <Button id="deposit" variant="success" className="w-100">
        Deposit
      </Button> */}
      {/* <Row id="showBalance" className="mt-4">
        <Col xs={1} className="">
          <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/60.png`} />
        </Col>
        <Col xs={8} className="float-start">
          {t('showbalance')}
        </Col>

        <Col xs={3} className="">
          <Form.Check type="switch" id="custom-switch-show-balance" className="float-end" />
        </Col>
      </Row> */}
      {/* <hr className="mt-1"></hr>
      <Row id="contact">
        <Col xs={1} className="float-start">
          <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/59.png`} />
        </Col>
        <Col xs={8} className="float-start">
          <Link to={'/contact'} onClick={onClose}>
            {t('contact')}
          </Link>
        </Col>

        <Col xs={3} >
          <span className="float-end text-muted">
            <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`} />
          </span>
        </Col>
      </Row> */}
      <hr className="mt-1"></hr>
      <Row className="h6">
        <Col xs={1}>
          <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/61.png`} />
        </Col>
        <Col xs={8} className="float-start">
          <Link to={'/profilesettings'} onClick={onClose}>
            {t('settings')}
          </Link>
        </Col>

        <Col xs={3}>
          <Link to={'/profilesettings'} className="float-end" onClick={onClose}>
            <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`} />
          </Link>
        </Col>
      </Row>
      <hr className="mt-1"></hr>
      <Row className="h6">
        <Col xs={1} className="">
          <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/62.png`} />
        </Col>
        <Col xs={8} className="float-start">
          <Link to={'/changepassword'} onClick={onClose}>
            {t('changepassword')}
          </Link>
        </Col>

        <Col xs={3}>
          <Link to={'/changepassword'} className="float-end" onClick={onClose}>
            <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`} />
          </Link>
        </Col>
      </Row>
      {/* <hr className="mt-1"></hr>
      <Row id="stayLoggedIn">
        <Col xs={1} className="">
          <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/63.png`} />
        </Col>
        <Col xs={8} className="float-start">
          {t('stayloggedin')}
        </Col>

        <Col xs={3} className="">
          <Form.Check type="switch" id="custom-switch-logged-in" className="float-end" />
        </Col>
      </Row> */}
      {/* <hr className="mt-1"></hr>
      <Row id="editProfile">
        <Col xs={1} className="">
          <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/64.png`} />
        </Col>
        <Col xs={8} className="float-start">
          <Link to={'/editprofile'} onClick={onClose}>
            {t('editprofile')}
          </Link>
        </Col>
        <Col xs={3} >
          <span className="float-end text-muted">
            <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`} />
          </span>
        </Col>
      </Row> */}
      <hr className="mt-1"></hr>
      {/* <Row id="uploadDocument">
        <Col xs={1} className="">
          <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/65.png`} />
        </Col>
        <Col xs={8} className="float-start">
          <Link to={'/uploaddocument'} onClick={onClose}>
            {t('uploaddocument')}
          </Link>
        </Col>
        <Col xs={3} >
          <span className="float-end text-muted">
            <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`} />
          </span>
        </Col>
      </Row>
      <hr className="mt-1"></hr> */}
      {/* <Row id="responsibleGaming" key="responsibleGaming">
        <Col xs={1} className="">
          <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/66.png`} />
        </Col>
        <Col xs={8} className="float-start">
          <Link to={'/responsiblegaming'} onClick={onClose}>
            {t('responsiblegaming')}
          </Link>
        </Col>
        <Col xs={3} >
          <span className="float-end text-muted">
            <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`} />
          </span>
        </Col>
      </Row>
      <hr className="mt-1"></hr> */}
      <Row key="bettingHistory" className="h6">
        <Col xs={1}>
          <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/67.png`} />
        </Col>
        <Col xs={8} className="float-start">
          <Link to={'/bettinghistory'} onClick={onClose}>
            {t('bettinghistory')}
          </Link>
        </Col>

        <Col xs={3}>
          <Link to={'/bettinghistory'} className="float-end" onClick={onClose}>
            <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`} />
          </Link>
        </Col>
      </Row>
      <hr className="mt-1"></hr>
      {/* <Row id="bank" key="bank">
        <Col xs={1} className="">
          <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/68.png`} />
        </Col>
        <Col xs={8} className="float-start">
          <Link to={'/bank'} onClick={onClose}>
            {t('bank')}
          </Link>
        </Col>
        <Col xs={3} >
          <span className="float-end text-muted">
            <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`} />
          </span>
        </Col>
      </Row>
      <hr className="mt-1"></hr> */}
      <Row key="myFinances" className="h6">
        <Col xs={1}>
          <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/68.png`} />
        </Col>
        <Col xs={8} className="float-start">
          <Link
            to={{
              pathname: `/transactions/${user.id}`
            }}
            onClick={onClose}>
            {t('transaction')}
          </Link>
        </Col>
        <Col xs={3}>
          <Link
            to={{
              pathname: `/transactions/${user.id}`
            }}
            className="float-end"
            onClick={onClose}>
            <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`} />
          </Link>
        </Col>
      </Row>
      <hr className="mt-1"></hr>
      {showQrDeposit && (
        <>
          <Row key="qrDeposit" className="h6">
            <Col xs={1}>
              <BsQrCodeScan size={24} />
            </Col>
            <Col xs={8} className="float-start">
              <Link to={'/qr-deposit'} onClick={onClose}>
                {t('qrDeposit')}
              </Link>
            </Col>

            <Col xs={3}>
              <Link to={'/qr-deposit'} className="float-end" onClick={onClose}>
                <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`} />
              </Link>
            </Col>
          </Row>
          <hr className="mt-1"></hr>
        </>
      )}
      <Row key="language" className="h6">
        <Col xs={8} className="float-start">
          {t('language')}
        </Col>
        <Col xs={3}>
          <Language />
        </Col>
      </Row>

      <hr className="mt-1"></hr>
      {/* <Row id="consentHistory" key="consentHistory">
        <Col xs={1} className="">
          <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/69.png`} />
        </Col>
        <Col xs={8} className="float-start">
          <Link to={'/consenthistory'} onClick={onClose}>
            {t('consenthistory')}
          </Link>
        </Col>
        <Col xs={3} >
          <span className="float-end text-muted">
            <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`} />
          </span>
        </Col>
      </Row>
      <hr className="mt-1"></hr> */}
      {/* <Row id="aboutNorobet" key="aboutNorobet" className="py-2">
        <Col xs={12}>
          <div className="hrtext">
            <p>About Norobet</p>
            <div className="headerhr"></div>
          </div>
        </Col>
      </Row>
      <Row id="information" key="information">
        <Col xs={1} className="float-start">
          <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/58.png`} />
        </Col>
        <Col xs={8} className="float-start">
          <Link to={'/information'} onClick={onClose}>
            {t('information')}
          </Link>
        </Col>
        <Col xs={3} >
          <span className="number">{count}</span>
          <span className="float-end text-muted">
            <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`} />
          </span>
        </Col>
      </Row> */}
      {user === null ? (
        <Link to="/login" onClick={onClose}>
          <Row className="h6">
            <div className="container mt-3">
              <div className="row justify-content-center">
                <button className="bg-danger border border-danger text-white p-2 w-100 fw-bold mt-2 text-center cursor-pointer">
                  Login
                </button>
              </div>
            </div>
          </Row>
        </Link>
      ) : (
        <Row className="h6" onClick={() => handleLogout()}>
          <div className="container mt-3">
            <div className="row justify-content-center m-1">
              <button className="bg-danger border border-danger rounded fw-bold text-white text-center cursor-pointer p-2 m-2">
                Logout
              </button>
            </div>
          </div>
        </Row>
      )}
    </Container>
  );
}
