import jwtDecode from 'jwt-decode';
import { backOfficeInstance, tmmInstance } from 'libraries/axios';

export function getToken() {
  return localStorage.getItem('token');
}

export function getShopId() {
  const token = getToken();
  if (token === undefined || token === null || token.length === 0) return '';
  const decoded = jwtDecode(token);
  if (decoded === undefined || decoded === null) return '';
  return decoded.ShopId;
}

export const postLoginReq = (body) => {
  return new Promise((resolve, reject) =>
    backOfficeInstance
      .post('/Auth/CustomerSignIn', body)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        if (error.response.data.errors[0] !== undefined) {
          const errorMessage = error.response.data.errors[0];
          reject(errorMessage);
        }
        reject(error);
      })
  );
};

export const fetchUserReq = (token) => {
  return new Promise((resolve, reject) =>
    backOfficeInstance
      .post('/Auth/CheckToken', {
        token,
        AccessDeviceEnum: 1
      })
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error, data) => {
        reject(error);
      })
  );
};

export const fetchBalanceReq = () => {
  return new Promise((resolve, reject) =>
    backOfficeInstance
      .post('/Customer/CheckCustomerBalance')
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      })
  );
};

export const updateProfileReq = (formData) => {
  return new Promise((resolve, reject) =>
    backOfficeInstance
      .post('/Customer/UpdateCustomerProfile', formData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
  );
};

export const updateSettingsReq = (formData) => {
  return new Promise((resolve, reject) =>
    backOfficeInstance
      .post('/Customer/UpdateCustomerSettings', formData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
  );
};

export const changePasswordReq = (formData) => {
  return new Promise((resolve, reject) =>
    backOfficeInstance
      .post('/Auth/ChangePassword', formData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
  );
};

export const getSlotGamesReq = (params) => {
  return new Promise((resolve, reject) =>
    backOfficeInstance
      .post('/Wallet/GetCasinoGameList', params)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      })
  );
};

export const startSessionReq = (params) => {
  return new Promise((resolve, reject) =>
    backOfficeInstance
      .post('/Wallet/OpenSession', params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
  );
};

export const startLiveCasinoSessionReq = (params) => {
  return new Promise((resolve, reject) =>
    backOfficeInstance
      .post('/Customer/OpenVivoLobby', params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
  );
};

export const getMyOpenBetsReq = (params) => {
  return new Promise((resolve, reject) =>
    tmmInstance
      .post('/Bet/GetMyOpenBetSlips', params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
  );
};

export const getMyClosedBetsReq = (params) => {
  return new Promise((resolve, reject) =>
    tmmInstance
      .post('/Bet/GetMyClosedBetSlips', params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
  );
};

export const getBettingHistoryWonReq = (params) => {
  return new Promise((resolve, reject) =>
    tmmInstance
      .post('/Bet/GetMyHistoryWonBetSlips', params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
  );
};

export const getBettingHistoryLostReq = (params) => {
  return new Promise((resolve, reject) =>
    tmmInstance
      .post('/Bet/GetMyHistoryLostBetSlips', params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
  );
};

export const getBettingHistoryCashoutReq = (params) => {
  return new Promise((resolve, reject) =>
    tmmInstance
      .post('/Bet/GetMyHistoryCashoutBetSlips', params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
  );
};

export const getCustomerTransactionsReq = (params) => {
  return new Promise((resolve, reject) =>
    backOfficeInstance
      .post('/customer/GetCustomerTransactions', params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
  );
};

export const getNotificationsReq = () => {
  return new Promise((resolve, reject) =>
    backOfficeInstance
      .get('/notification')
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
  );
};

export const getSingleBetSlipReq = (params) => {
  return new Promise((resolve, reject) =>
    tmmInstance
      .post('/Bet/GetSingleBetSlip', params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
  );
};

export const startLiveEvoCasinoSessionReq = (params) => {
  return new Promise((resolve, reject) =>
    backOfficeInstance
      .post('/LiveWallet/OpenGames', params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
  );
};

export const getEvoGamesReq = async () => {
  const response = await backOfficeInstance.get(`/LiveWallet`);
  return response.data.data;
};

export const addDepositCardQRReq = async (body) => {
  const response = await backOfficeInstance.post('/Shop/AddDepositCardQR', body);
  return response.data;
};
