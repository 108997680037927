import { COOKIE_KEYS, sports } from './constants.js';
import i18n from '../libraries/i18n.js';
import { AWS_BUCKET_URL } from '../toolkit/constants';
import { getTeamNameHelper } from 'helper/matchDetailHelper.js';
import { EnumMarketName, EnumMatchStatusName } from './Enums.js';

export const getLanguageCode = () => {
  // const lang = getCookieValue(COOKIE_KEYS.LANGUAGE_CODE);
  // if (lang === undefined) {
  //   setLanguageCode("en");
  //   return "en";
  // } else {
  //   return getCookieValue(COOKIE_KEYS.LANGUAGE_CODE);
  // }
  const language = localStorage.getItem('i18nextLng');
  setLanguageCode(language.split('-')[0]);
  return language;
};

export const setLanguageCode = (lang) => {
  deleteCookie(lang);
  setCookie(COOKIE_KEYS.LANGUAGE_CODE, lang, 365);
};

export const getCookieValue = (cookieName) => {
  const keyValues = document.cookie.split('; ');
  if (keyValues.length > 0) {
    const cookieValue = keyValues.find((row) => row.startsWith(cookieName + '='));
    return cookieValue !== undefined ? cookieValue.split('=')[1] : '';
  }

  return '';
};

export const setCookie = (name, value, days) => {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
};

export const deleteCookie = (name) => {
  document.cookie = name + '=; Max-Age=-99999999;';
};

export function OddsValueTypeTextCreator(sportType, marketId, outcomeId, outcomeName) {
  if (outcomeName === undefined) {
    outcomeName = '';
  }
  switch (sportType.toString()) {
    // football Odds
    case '0':
      return getFootballOddTypeText(marketId, outcomeId, outcomeName);
    default:
      return getOtherSportsOddTypeText(marketId, outcomeId, outcomeName);
  }
}

export function MyBetsTextCreator(sportType, marketId, outcomeId, outcomeName) {
  if (outcomeName === undefined) {
    outcomeName = '';
  }
  switch (sportType) {
    // football Odds
    case 0:
      return getFootballMyBetsText(marketId, outcomeId, outcomeName);
    default:
      return getOtherSportsOddTypeText(marketId, outcomeId, outcomeName);
  }
}

// showfulltext will be used for only bettinghistoryitem and betslip row
export function MarketIdToMarketName(marketId, stringId, showOnlyMarket = true) {
  var marketNameText = '';
  if (stringId.includes('-S:')) {
    if (stringId.split('-S:')[1].includes('sr')) {
      return i18n.t(`markets.${marketId}`);
    }
    if (showOnlyMarket) {
      const marketIds = [
        7, 8, 14, 16, 18, 19, 20, 37, 38, 39, 40, 41, 62, 65, 66, 68, 69, 70, 84, 90, 91, 92, 100,
        114, 116, 166, 187, 189, 199, 223, 225, 237, 238, 256, 258, 547, 891
      ];
      if (marketIds.includes(marketId)) {
        return i18n.t(`markets.${marketId}`);
      }
    }
    if (
      marketId == EnumMarketName.ROM_7 ||
      marketId == EnumMarketName.NEXTGOALSCORER_38 ||
      marketId == EnumMarketName.LASTGOALSCORER_39 ||
      marketId == EnumMarketName.ANYTIMEGOALSCORER_40 ||
      marketId == EnumMarketName.CORRECTSCORE_41 ||
      marketId == EnumMarketName.FIRSTHALFNEXTGOAL_62 ||
      marketId == EnumMarketName.CORRECTSCORE_199 ||
      marketId == EnumMarketName.FRAMEHANDICAP_493 ||
      marketId == EnumMarketName.TOTALFRAMES_494 ||
      marketId == EnumMarketName.NEXTSCORINGTYPE_896 ||
      marketId == EnumMarketName.FIRSTHALFROM_61 ||
      marketId == EnumMarketName.OVERTIMENEXTGOAL_115
    ) {
      return i18n.t(`markets.${marketId}`);
    } else if (
      marketId == EnumMarketName.HANDICAP_14 ||
      marketId == EnumMarketName.TOTAL_18 ||
      marketId == EnumMarketName.HOMETOTAL_19 ||
      marketId == EnumMarketName.AWAYTOTAL_20 ||
      marketId == EnumMarketName.WINNERANDOVERUNDER_37 ||
      marketId == EnumMarketName.FIRSTHALFHANDICAP_65 ||
      marketId == EnumMarketName.FIRSTHALFOVERUNDER_68 ||
      marketId == EnumMarketName.FIRSTHALFHOMEOVERUNDER_69 ||
      marketId == EnumMarketName.FIRSTHALFAWAYOVERUNDER_70 ||
      marketId == EnumMarketName.OVERUNDERCORNERS_166 ||
      marketId == EnumMarketName.TOTAL_225 ||
      marketId == EnumMarketName.TOTALPOINT_238 ||
      marketId == EnumMarketName.TOTAL_258 ||
      marketId == EnumMarketName.DOUBLECHANCEOVERUNDER_547 ||
      marketId == EnumMarketName.NEXTGOALSCORERWINNER_891
    ) {
      marketNameText = stringId.split('-S:')[1];
      return i18n.t(`markets.${marketId}`) + marketNameText;
    } else if (
      marketId == EnumMarketName.HOMEOVERUNDER_227 ||
      marketId == EnumMarketName.AWAYOVERUNDER_228
    ) {
      marketNameText = stringId.split('-S:')[1];
      return i18n.t(`markets.${marketId}`);
    } else if (
      marketId == EnumMarketName.HOMEOVERUNDER_756 ||
      marketId == EnumMarketName.AWAYOVERUNDER_757
    ) {
      marketNameText = stringId.split('-S:')[1];
      return 'Q' + marketNameText + ' ' + i18n.t(`markets.${marketId}`);
    } else if (marketId == EnumMarketName.SETHANDICAP_188) {
      marketNameText = '0:' + stringId.split('S:')[1];
      return i18n.t(`markets.${marketId}`) + ' ' + marketNameText;
    } else if (marketId == EnumMarketName.NEXTGOAL_8) {
      marketNameText = stringId.split('-S:')[1];
      return i18n.t(`markets.${marketId}`) + ' ' + marketNameText;
    } else if (
      marketId == EnumMarketName.GAMEHANDICAP_187 ||
      marketId == EnumMarketName.HANDICAP_223 ||
      marketId == EnumMarketName.HANDICAP_256
    ) {
      marketNameText = stringId;
      // if (stringId.includes('-S:-')) {
      //   marketNameText = stringId.split('-')[3];
      // } else {
      //   marketNameText = stringId.split(':')[1];
      // }
      if (stringId.split('-S:')[1].includes('-')) {
        marketNameText =
          i18n.t(`markets.${marketId}`) + ' ' + '0:' + stringId.split('-S:')[1].slice(1);
      } else {
        marketNameText = i18n.t(`markets.${marketId}`) + ' ' + stringId.split('-S:')[1] + ':0';
      }
      return marketNameText;
    } else if (marketId == EnumMarketName.INNINGSTOTAL_605) {
      marketNameText = stringId.split('-S:1-S:')[1];
      return i18n.t(`markets.${marketId}`) + ' ' + marketNameText;
    } else if (
      marketId == EnumMarketName.FIRSTHALFHANDICAP_66 ||
      marketId == EnumMarketName.POINTHANDICAP_237 ||
      marketId == EnumMarketName.HANDICAP_16
    ) {
      marketNameText = stringId;
      if (stringId.includes('-S:-')) {
        marketNameText = '0:' + stringId.split('-')[3];
        return i18n.t(`markets.${marketId}`) + ' ' + marketNameText;
      } else {
        marketNameText = i18n.t(`markets.${marketId}`) + ' ' + stringId.split('-S:')[1] + ':0';
        return marketNameText;
      }
    } else if (
      marketId === EnumMarketName.QUARTEROVERUNDER_236 ||
      marketId === EnumMarketName.QUARTERHANDICAP_303
    ) {
      const splittedMarket = stringId.split('-S:');
      marketNameText = splittedMarket[1];
      return i18n.t(`markets.${marketId}`) + ' ' + marketNameText + ' ' + splittedMarket[2];
    } else {
      marketNameText = stringId.split('-S:')[1];
      return i18n.t(`markets.${marketId}`) + ' ' + marketNameText;
    }
  } else {
    return i18n.t(`markets.${marketId}`);
  }
}

export function checkHasMarketVariable(marketId, stringId) {
  if (
    marketId == EnumMarketName.HANDICAP_14 ||
    marketId == EnumMarketName.TOTAL_18 ||
    marketId == EnumMarketName.HOMETOTAL_19 ||
    marketId == EnumMarketName.AWAYTOTAL_20 ||
    marketId == EnumMarketName.WINNERANDOVERUNDER_37 ||
    // marketId === 62 ||
    marketId == EnumMarketName.FIRSTHALFHANDICAP_65 ||
    marketId == EnumMarketName.FIRSTHALFOVERUNDER_68 ||
    marketId == EnumMarketName.FIRSTHALFHOMEOVERUNDER_69 ||
    marketId == EnumMarketName.FIRSTHALFAWAYOVERUNDER_70 ||
    marketId === EnumMarketName.SECONDHALFOVERUNDER_90 ||
    marketId === EnumMarketName.SECONDHALFHOMEOVERUNDER_91 ||
    marketId === EnumMarketName.SECONDHALFAWAYOVERUNDER_92 ||
    marketId == EnumMarketName.OVERUNDERCORNERS_166 ||
    marketId == EnumMarketName.TOTAL_225 ||
    marketId == EnumMarketName.TOTALPOINT_238 ||
    marketId == EnumMarketName.TOTAL_258 ||
    marketId == EnumMarketName.DOUBLECHANCEOVERUNDER_547 ||
    marketId == EnumMarketName.HOMEOVERUNDER_756 ||
    marketId == EnumMarketName.AWAYOVERUNDER_757 ||
    marketId == EnumMarketName.HOMEOVERUNDER_227 ||
    marketId == EnumMarketName.AWAYOVERUNDER_228
  ) {
    return stringId?.split('-S:')[1] || true;
  }
  return false;
}

export function gameDateToMatchDayFormatted(date, locale) {
  const matchDate = new Date(date);
  const today = new Date();
  const isT =
    matchDate.getDate() === today.getDate() &&
    matchDate.getMonth() === today.getMonth() &&
    matchDate.getFullYear() === today.getFullYear();
  return isT === true ? i18n.t('today') : matchDate.toLocaleDateString(locale);
}

export function convertToTime(time) {
  let hour = Number(time.split(':')[0]);
  if (hour < 12) {
    const minute = time.split(':')[1];
    hour = (hour + 12).toString();
    return hour.concat(':', minute);
  } else {
    return time;
  }
}

export function gameDateToMatchDay(date) {
  const matchDate = new Date(date);
  const today = new Date();
  const isT =
    matchDate.getDate() === today.getDate() &&
    matchDate.getMonth() === today.getMonth() &&
    matchDate.getFullYear() === today.getFullYear();
  return isT === true ? i18n.t('today') : matchDate.getDate() + '.' + (matchDate.getMonth() + 1);
}

export function gameDateToMatchTime(gameDate) {
  const matchDate = new Date(gameDate);
  return (
    matchDate.getHours() + ':' + (matchDate.getMinutes() < 10 ? '0' : '') + matchDate.getMinutes()
  );
}

export function getFootballOddTypeText(marketId, outcomeId, outcomeName) {
  switch (marketId) {
    //winner
    case '1':
    case '83':
      switch (outcomeId) {
        case '1':
          return '1';
        case '2':
          return 'X';
        case '3':
          return '2';
        default:
          return null;
      }
    //to qualify
    case '2':
      switch (outcomeId) {
        case '1':
        case '4':
          return '1';
        case '2':
        case '5':
          return '2';
        default:
          return null;
      }
    case '3':
      switch (outcomeId) {
        case '4':
          return '1';
        case '5':
          return '2';
        default:
          return outcomeName;
      }
    //Winning method
    case '5':
      switch (outcomeId) {
        case '14':
          return i18n.t('oddType.homeRegularTime');
        case '15':
          return i18n.t('oddType.awayRegularTime');
        case '16':
          return i18n.t('oddType.homeOvertime');
        case '17':
          return i18n.t('oddType.awayOvertime');
        case '18':
          return i18n.t('oddType.homePenalties');
        case '19':
          return i18n.t('oddType.awayPenalties');
        default:
          return null;
      }
    //rest of the match
    case '7':
      switch (outcomeId) {
        case '1':
          return '1';
        case '2':
          return 'X';
        case '3':
          return '2';
        default:
          return null;
      }
    //1st goal
    case '8':
      switch (outcomeId) {
        case '6':
          return '1';
        case '7':
          return 'X';
        case '8':
          return '2';
        default:
          return null;
      }
    //Last Goal
    case '9':
      switch (outcomeId) {
        case '6':
          return '1';
        case '7':
          return 'X';
        case '8':
          return '2';
        default:
          return null;
      }
    //Double Chance
    case '10':
    case '63':
    case '85':
      switch (outcomeId) {
        case '9':
          return '1X';
        case '10':
          return '12';
        case '11':
          return 'X2';
        default:
          return null;
      }
    //Draw no bet
    case '11':
    case '86':
      switch (outcomeId) {
        case '4':
          return '1';
        case '5':
          return '2';
        default:
          return null;
      }
    //Handicap
    case '14':
      switch (outcomeId) {
        case '1711':
          return '1';
        case '1712':
          return 'X';
        case '1713':
          return '2';
        default:
          return null;
      }
    //Over/Under
    case '18':
    case '90':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return null;
      }
    //Home Over/Under
    case '19':
    case '91':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return null;
      }
    //Away Over/Under
    case '20':
    case '92':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return null;
      }
    //Exact Goals
    case '21':
    case '41':
    case '71':
      return outcomeName;
    //Home Exact Goals
    case '23':
    case '24':
      switch (outcomeId.split(':')[3]) {
        case '88':
          return '0';
        case '89':
          return '1';
        case '90':
          return '2';
        case '91':
          return '3+';
        default:
          return null;
      }
    // Goal Range
    case '25':
      switch (outcomeId.split(':')[3]) {
        case '1121':
          return '0-1';
        case '1122':
          return '2-3';
        case '1123':
          return '4-6';
        case '1124':
          return '6+';
        case '1342':
          return '0-1';
        case '1343':
          return '2-3';
        case '1344':
          return '4-6';
        case '1345':
          return '7+';
        default:
          return outcomeId;
      }
    // Odd/Even
    case '26':
    case '94':
      switch (outcomeId) {
        case '70':
          return i18n.t('oddType.odd');
        case '72':
          return i18n.t('oddType.even');
        default:
          return null;
      }
    // Both Teams to Score
    case '29':
    case '33':
    case '34':
    case '48':
    case '49':
    case '50':
    case '51':
    case '56':
    case '57':
    case '58':
    case '59':
    case '75':
    case '95':
    case '112':
    case '122':
    case '341':
      switch (outcomeId) {
        case '74':
          return i18n.t('oddType.yes');
        case '76':
          return i18n.t('oddType.no');
        default:
          return null;
      }
    // Which Team to Score
    case '30':
      switch (outcomeId) {
        case '784':
          return i18n.t('oddType.noGoal');
        case '788':
          return '1';
        case '790':
          return '2';
        case '792':
          return '1X2';
        default:
          return null;
      }
    //Winner and Both Teams To Score
    case '35':
      switch (outcomeId) {
        case '78':
          return '1 ' + i18n.t('oddType.yes');
        case '80':
          return '1 ' + i18n.t('oddType.no');
        case '82':
          return 'X ' + i18n.t('oddType.yes');
        case '84':
          return 'X ' + i18n.t('oddType.no');
        case '86':
          return '2 ' + i18n.t('oddType.yes');
        case '88':
          return '2 ' + i18n.t('oddType.no');
        default:
          return null;
      }
    // Over under and both teams to score
    case '36':
      switch (outcomeId) {
        case '90':
          return '+ ' + i18n.t('oddType.yes');
        case '92':
          return '- ' + i18n.t('oddType.yes');
        case '94':
          return '+ ' + i18n.t('oddType.no');
        case '96':
          return '- ' + i18n.t('oddType.no');
        default:
          return null;
      }
    // Winner and over Under
    case '37':
      switch (outcomeId) {
        case '794':
          return '1/-';
        case '796':
          return '1/+';
        case '798':
          return 'X/-';
        case '800':
          return 'X/+';
        case '802':
          return '2/-';
        case '804':
          return '2/+';
        default:
          return null;
      }
    // First Goal Scorer
    // Last GoalScorer
    // AnyTime GoalScorer
    case '38':
    case '39':
    case '40':
      if (outcomeName.includes(',')) {
        return outcomeName.split(',')[1] + ' ' + outcomeName.split(',')[0];
      } else {
        return outcomeName;
      }
    // Halftime/Fulltime
    case '47':
      switch (outcomeId) {
        case '418':
          return '1/1';
        case '420':
          return '1/X';
        case '422':
          return '1/2';
        case '424':
          return 'X/1';
        case '426':
          return 'X/X';
        case '428':
          return 'X/2';
        case '430':
          return '2/1';
        case '432':
          return '2/X';
        case '434':
          return '2/2';
        default:
          return null;
      }
    //Highest scoring half
    case '52':
      switch (outcomeId) {
        case '436':
          return i18n.t('oddType.firstHalf');
        case '438':
          return i18n.t('oddType.secondHalf');
        case '440':
          return i18n.t('oddType.equal');
        default:
          return null;
      }
    // both half goal
    case '55':
      switch (outcomeId) {
        case '806':
          return i18n.t('oddType.no') + '/' + i18n.t('oddType.no');
        case '808':
          return i18n.t('oddType.yes') + '/' + i18n.t('oddType.no');
        case '810':
          return i18n.t('oddType.yes') + '/' + i18n.t('oddType.yes');
        case '812':
          return i18n.t('oddType.no') + '/' + i18n.t('oddType.yes');
        default:
          return null;
      }
    // 1st half winner
    case '60':
      switch (outcomeId) {
        case '1':
          return '1';
        case '2':
          return 'X';
        case '3':
          return '2';
        default:
          return null;
      }
    //1st half rest of the match
    case '61':
      switch (outcomeId) {
        case '1':
          return '1';
        case '2':
          return 'X';
        case '3':
          return '2';
        default:
          return null;
      }
    // 1st half first goal
    case '62':
    case '84':
      switch (outcomeId) {
        case '6':
          return '1';
        case '7':
          return 'X';
        case '8':
          return '2';
        default:
          return null;
      }

    // 1st half draw no bet
    case '64':
      switch (outcomeId) {
        case '4':
          return '1';
        case '5':
          return '2';
        default:
          return null;
      }
    // 1st half handicap
    case '65':
      switch (outcomeId) {
        case '1711':
          return '1';
        case '1712':
          return 'X';
        case '1713':
          return '2';
        default:
          return null;
      }
    // 1st half away over/under
    case '68':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return null;
      }
    case '69':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return null;
      }
    case '70':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return null;
      }
    // 1st half exact goals && when will the 1st goal be scored 15 minutes interval
    case '78':
      switch (outcomeId) {
        case '78':
          return '1 &' + i18n.t('oddType.yes');
        case '80':
          return '1 &' + i18n.t('oddType.no');
        case '82':
          return 'X &' + i18n.t('oddType.yes');
        case '84':
          return 'X &' + i18n.t('oddType.no');
        case '86':
          return '2 &' + i18n.t('oddType.yes');
        case '88':
          return '2 &' + i18n.t('oddType.no');
        default:
          return null;
      }
    case '79':
      switch (outcomeId) {
        case '794':
          return '1 & -';
        case '796':
          return '1 & +';
        case '798':
          return 'X & -';
        case '800':
          return 'X & +';
        case '802':
          return '2 & -';
        case '804':
          return '2 & +';
        default:
          return outcomeName;
      }

    case '100':
      switch (outcomeId) {
        case '596':
          return i18n.t('oddType.none');
        default:
          return outcomeName;
      }
    case '113':
      switch (outcomeId) {
        case '1':
          return '1';
        case '2':
          return 'X';
        case '3':
          return '2';
        default:
          return null;
      }
    case '114':
      switch (outcomeId) {
        case '1':
          return '1';
        case '2':
          return 'X';
        case '3':
          return '2';
        default:
          return null;
      }
    case '115':
      switch (outcomeId) {
        case '6':
          return '1';
        case '7':
          return 'X';
        case '8':
          return '2';
        default:
          return null;
      }
    case '74':
    case '93':
      switch (outcomeId) {
        case '70':
          return i18n.t('common:odds.odd');
        case '72':
          return i18n.t('common:odds.even');
        default:
          return null;
      }
    //Corner winner
    case '162':
      switch (outcomeId) {
        case '1':
          return '1';
        case '2':
          return 'X';
        case '3':
          return '2';
        default:
          return null;
      }
    case '116':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return null;
      }
    //Over/Under Corner
    case '166':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return null;
      }

    // double chance and first half double chance exact
    case '540':
    case '542':
    case '546':
      switch (outcomeId) {
        case '1718':
          return '1/X ' + i18n.t('oddType.yes');
        case '1719':
          return '1/X ' + i18n.t('oddType.no');
        case '1720':
          return '1/2 ' + i18n.t('oddType.yes');
        case '1721':
          return '1/2 ' + i18n.t('oddType.no');
        case '1722':
          return 'X/2 ' + i18n.t('oddType.yes');
        case '1723':
          return 'X/2 ' + i18n.t('oddType.no');
        default:
          return null;
      }
    // double chance and over under
    case '547':
      switch (outcomeId) {
        case '1724':
          return '1X -';
        case '1725':
          return '12 -';
        case '1726':
          return 'X2 -';
        case '1727':
          return '1X +';
        case '1728':
          return '12 +';
        case '1729':
          return 'X2 +';
        default:
          return null;
      }
    case '551':
      switch (outcomeId) {
        case '1803':
          return i18n.t('oddType.draw');
        case '1750':
          return '1:0, 2:0 or 3:0';
        case '1751':
          return '0:1, 0:2 or 0:3';
        case '1752':
          return '4:0, 5:0 or 6:0';
        case '1753':
          return '0:4, 0:5 or 0:6';
        case '1754':
          return '2:1, 3:1 or 4:1';
        case '1755':
          return '1:2, 1:3 or 1:4';
        case '1756':
          return '3:2, 4:2, 4:3 or 5:1';
        case '1757':
          return '2:3, 2:4, 3:4 or 1:5';
        case '1758':
          return i18n.t('oddType.otherHomeWin');
        case '1759':
          return i18n.t('oddType.otherAwayWin');
        default:
          return null;
      }
    case '552':
      switch (outcomeId) {
        case '1805':
          return i18n.t('oddType.noGoal');
        case '1746':
          return '1-2';
        case '1747':
          return '1-3';
        case '1748':
          return '2-3';
        case '1749':
          return '4+';
        default:
          return null;
      }
    case '818':
    case '819':
      switch (outcomeId) {
        case '1836':
          return '1/1 -';
        case '1837':
          return '1/X -';
        case '1838':
          return '1/2 -';
        case '1839':
          return 'X/1 -';
        case '1840':
          return 'X/X -';
        case '1841':
          return 'X/2 -';
        case '1842':
          return '2/1 -';
        case '1843':
          return '2/X -';
        case '1844':
          return '2/2 -';
        case '1845':
          return '1/1 +';
        case '1846':
          return '1/X +';
        case '1847':
          return '1/2 +';
        case '1848':
          return 'X/1 +';
        case '1849':
          return 'X/X +';
        case '1850':
          return 'X/2 +';
        case '1851':
          return '2/1 +';
        case '1852':
          return '2/X +';
        case '1853':
          return '2/2 +';
        default:
          return outcomeName;
      }
    case '820':
      switch (outcomeId) {
        case '1854':
          return 'X/X 0';
        case '1855':
          return '1/1 1';
        case '1856':
          return 'X/1 1';
        case '1857':
          return 'X/2 1';
        case '1858':
          return '2/2 1';
        case '1859':
          return '1/1 2';
        case '1860':
          return '1/X 2';
        case '1861':
          return 'X/1 2';
        case '1862':
          return 'X/X 2';
        case '1863':
          return 'X/2 2';
        case '1864':
          return '2/X 2';
        case '1865':
          return '2/2 2';
        case '1866':
          return '1/1 3';
        case '1867':
          return '1/2 3';
        case '1868':
          return 'X/1 3';
        case '1869':
          return 'X/2 3';
        case '1870':
          return '2/X 3';
        case '1871':
          return '2/2 3';
        case '1872':
          return '1/1 4';
        case '1873':
          return '1/X 4';
        case '1874':
          return '1/2 4';
        case '1875':
          return 'X/1 4';
        case '1876':
          return 'X/X 4';
        case '1877':
          return 'X/2 4';
        case '1878':
          return '2/1 4';
        case '1879':
          return '2/X 4';
        case '1880':
          return '2/2 4';
        case '1881':
          return '1/1 5+';
        case '1882':
          return '1/X 5+';
        case '1883':
          return '1/2 5+';
        case '1884':
          return 'X/1 5+';
        case '1885':
          return 'X/X 5+';
        case '1886':
          return 'X/2 5+';
        case '1887':
          return '2/1 5+';
        case '1888':
          return '2/X 5+';
        case '1889':
          return '2/2 5+';
        default:
          return null;
      }
    // goalscorer
    case '888':
    case '891':
      switch (outcomeId) {
        case '1894':
          return '1';
        case '1895':
          return '0';
        case '1896':
          return '2';
        case '1897':
          return i18n.t('oddType.other');
        default:
          return outcomeName;
      }
    case '896':
      switch (outcomeId) {
        case '1937':
          return i18n.t('oddType.shot');
        case '1938':
          return i18n.t('oddType.header');
        case '1939':
          return i18n.t('oddType.ownGoal');
        case '1940':
          return i18n.t('oddType.penalty');
        case '1941':
          return i18n.t('oddType.freeKick');
        case '1942':
          return i18n.t('oddType.none');
        default:
          return i18n.t(outcomeName);
      }
    default:
      return i18n.t(outcomeName);
  }
}

export function getOtherSportsOddTypeText(marketId, outcomeId, outcomeName) {
  switch (marketId) {
    case '1':
      switch (outcomeId) {
        case '1':
          return '1';
        case '2':
          return 'X';
        case '3':
          return '2';
        default:
          return outcomeName;
      }
    case '8':
      switch (outcomeId) {
        case '6':
          return '1';
        case '7':
          return 'X';
        case '8':
          return '2';
        default:
          return outcomeName;
      }
    case '10':
    case '63':
    case '85':
      switch (outcomeId) {
        case '9':
          return '1X';
        case '10':
          return '12';
        case '11':
          return 'X2';
        default:
          return null;
      }
    case '11':
      switch (outcomeId) {
        case '4':
          return '1';
        case '5':
          return '2';
        default:
          return null;
      }
    //Handicap
    case '14':
      switch (outcomeId) {
        case '1711':
          return '1';
        case '1712':
          return 'X';
        case '1713':
          return '2';
        default:
          return null;
      }
    case '16':
      switch (outcomeId) {
        case '1714':
          return '1';
        case '1715':
          return '2';
        default:
          return outcomeName;
      }
    //Over/Under
    case '18':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return null;
      }
    //Home Over/Under
    case '19':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return null;
      }
    //Away Over/Under
    case '20':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return outcomeName;
      }

    case '29':
    case '33':
    case '34':
    case '48':
    case '49':
    case '50':
    case '51':
    case '56':
    case '57':
    case '58':
    case '59':
    case '75':
    case '95':
    case '112':
    case '122':
    case '341':
      switch (outcomeId) {
        case '74':
          return 'Yes';
        case '76':
          return 'No';
        default:
          return outcomeName;
      }
    case '37':
      switch (outcomeId) {
        case '794':
          return '1/-';
        case '796':
          return '1/+';
        case '798':
          return 'X/-';
        case '800':
          return 'X/+';
        case '802':
          return '2/-';
        case '804':
          return '2/+';
        default:
          return outcomeName;
      }
    case '47':
      switch (outcomeId) {
        case '418':
          return '1/1';
        case '420':
          return '1/X';
        case '422':
          return '1/2';
        case '424':
          return 'X/1';
        case '426':
          return 'X/X';
        case '428':
          return 'X/2';
        case '430':
          return '2/1';
        case '432':
          return '2/X';
        case '434':
          return '2/2';
        default:
          return outcomeName;
      }
    case '60':
      switch (outcomeId) {
        case '1':
          return '1';
        case '2':
          return 'X';
        case '3':
          return '2';
        default:
          return outcomeName;
      }
    case '64':
      switch (outcomeId) {
        case '4':
          return '1';
        case '5':
          return '2';
        default:
          return outcomeName;
      }
    case '65':
      switch (outcomeId) {
        case '1711':
          return '1';
        case '1712':
          return 'X';
        case '1713':
          return '2';
        default:
          return outcomeName;
      }
    case '66':
      switch (outcomeId) {
        case '1714':
          return '1';
        case '1715':
          return '2';
        default:
          return outcomeName;
      }
    case '68':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return outcomeName;
      }
    case '69':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return outcomeName;
      }
    case '70':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return outcomeName;
      }
    case '74':
      switch (outcomeId) {
        case '70':
          return i18n.t('odd');
        case '72':
          return i18n.t('even');
        default:
          return outcomeName;
      }
    case '186':
      switch (outcomeId) {
        case '4':
          return '1';
        case '5':
          return '2';
        default:
          return outcomeName;
      }
    case '187':
      switch (outcomeId) {
        case '1714':
          return '1';
        case '1715':
          return '2';
        default:
          return outcomeName;
      }
    case '188':
      switch (outcomeId) {
        case '1714':
          return '1';
        case '1715':
          return '2';
        default:
          return outcomeName;
      }
    case '189':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return outcomeName;
      }
    case '192':
    case '193':
    case '196':
    case '199':
    case '329':
      return i18n.t(outcomeName);
    case '201':
      switch (outcomeId) {
        case '861':
          return '1/1';
        case '862':
          return '2/1';
        case '863':
          return '1/2';
        case '864':
          return '2/2';
        default:
          return outcomeName;
      }
    case '202':
      switch (outcomeId) {
        case '4':
          return '1';
        case '5':
          return '2';
        default:
          return null;
      }
    case '203':
      switch (outcomeId) {
        case '1714':
          return '1';
        case '1715':
          return '2';
        default:
          return outcomeName;
      }
    case '204':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return outcomeName;
      }
    case '207':
      return outcomeName;
    case '219':
      switch (outcomeId) {
        case '4':
          return '1';
        case '5':
          return '2';
        default:
          return null;
      }
    case '223':
      switch (outcomeId) {
        case '1714':
          return '1';
        case '1715':
          return '2';
        default:
          return outcomeName;
      }
    case '225':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return null;
      }
    case '227':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return null;
      }
    case '228':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return null;
      }
    case '234':
      switch (outcomeId) {
        case '920':
          return 'Q1';
        case '921':
          return 'Q2';
        case '922':
          return 'Q3';
        case '923':
          return 'Q4';
        case '924':
          return i18n.t('oddType.equal');
        default:
          return outcomeName;
      }
    case '235':
      switch (outcomeId) {
        case '1':
          return '1';
        case '2':
          return 'X';
        case '3':
          return '2';
        default:
          return null;
      }
    case '236':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return null;
      }
    case '237':
      switch (outcomeId) {
        case '1714':
          return '1';
        case '1715':
          return '2';
        default:
          return outcomeName;
      }
    case '238':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return null;
      }
    case '251':
      switch (outcomeId) {
        case '4':
          return '1';
        case '5':
          return '2';
        default:
          return null;
      }
    case '256':
      switch (outcomeId) {
        case '1714':
          return '1';
        case '1715':
          return '2';
        default:
          return outcomeName;
      }
    case '258':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return null;
      }
    case '292':
    case '1055':
      switch (outcomeId) {
        case '973':
          return '1 +';
        case '974':
          return '2 +';
        case '975':
          return '1 -';
        case '976':
          return '2 -';
        default:
          return null;
      }
    case '303':
      switch (outcomeId) {
        case '1714':
          return '1';
        case '1715':
          return '2';
        default:
          return outcomeName;
      }
    case '314':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return outcomeName;
      }
    case '327':
      switch (outcomeId) {
        case '1714':
          return '1';
        case '1715':
          return '2';
        default:
          return outcomeName;
      }
    case '328':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return outcomeName;
      }
    case '340':
      switch (outcomeId) {
        case '4':
          return '1';
        case '5':
          return '2';
        default:
          return null;
      }
    case '493':
      switch (outcomeId) {
        case '1714':
          return '1';
        case '1715':
          return '2';
        default:
          return outcomeName;
      }
    case '491':
      switch (outcomeId) {
        case '1':
          return '1';
        case '2':
          return 'X';
        case '3':
          return '2';
        default:
          return null;
      }
    case '494':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return outcomeName;
      }
    // xth frame - winner
    case '499':
      switch (outcomeId) {
        case '4':
          return '1';
        case '5':
          return '2';
        default:
          return null;
      }
    case '605':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return null;
      }
    case '756':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return null;
      }
    case '757':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return null;
      }
    case '1056':
      switch (outcomeId) {
        case '973':
          return '1/+';
        case '974':
          return '2/+';
        case '975':
          return '1/-';
        case '976':
          return '2/-';
        default:
          return outcomeName;
      }
    default:
      return i18n.t(outcomeName);
  }
}

export function getSportTypeToIcon(sportType) {
  switch (sportType) {
    case 0:
      return (
        <img
          className="icon-white "
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/football.svg`}
        />
      );
    case 1:
      return (
        <img
          className="icon-white "
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/basketball.svg`}
        />
      );
    case 2:
      return (
        <img
          className="icon-white "
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/$0.png`}
        />
      );
    case 8:
      return (
        <img
          className="icon-white "
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/79.png`}
        />
      );
    case 9:
      return (
        <img
          className="icon-white "
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/82.png`}
        />
      );
    case 13:
    default:
      return null;
  }
}

export function getFootballMyBetsText(marketId, outcomeId, outcomeName) {
  switch (marketId) {
    //winner
    case '1':
      switch (outcomeId) {
        case '1':
          return '1';
        case '2':
          return 'X';
        case '3':
          return '2';
        default:
          return null;
      }
    //to qualify
    case '2':
      switch (outcomeId) {
        case '4':
          return '1';
        case '5':
          return '2';
        default:
          return null;
      }
    case '5':
      switch (outcomeId) {
        case '14':
          return i18n.t('oddType.homeRegularTime');
        case '15':
          return i18n.t('oddType.awayRegularTime');
        case '16':
          return i18n.t('oddType.homeOvertime');
        case '17':
          return i18n.t('oddType.awayOvertime');
        case '18':
          return i18n.t('oddType.homePenalties');
        case '19':
          return i18n.t('oddType.awayPenalties');
        default:
          return null;
      }
    //rest of the match
    case '7':
      switch (outcomeId) {
        case '1':
          return '1';
        case '2':
          return 'X';
        case '3':
          return '2';
        default:
          return null;
      }
    //1st goal
    case '8':
      switch (outcomeId) {
        case '6':
          return '1';
        case '7':
          return 'X';
        case '8':
          return '2';
        default:
          return null;
      }
    //Last Goal
    case '9':
      switch (outcomeId) {
        case '6':
          return '1';
        case '7':
          return 'X';
        case '8':
          return '2';
        default:
          return null;
      }
    //Double Chance
    case '10':
    case '63':
    case '85':
      switch (outcomeId) {
        case '9':
          return '1X';
        case '10':
          return '12';
        case '11':
          return 'X2';
        default:
          return null;
      }
    //Draw no bet
    case '11':
      switch (outcomeId) {
        case '4':
          return '1';
        case '5':
          return '2';
        default:
          return null;
      }
    //Handicap
    case '14':
      switch (outcomeId) {
        case '1711':
          return '1';
        case '1712':
          return 'X';
        case '1713':
          return '2';
        default:
          return null;
      }
    //Over/Under
    case '18':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return null;
      }
    //Home Over/Under
    case '19':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return null;
      }
    //Away Over/Under
    case '20':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return null;
      }
    //Exact Goals
    case '21':
    case '71':
      return outcomeId;
    // switch (outcomeId.split(':')[3]) {
    //   case '75':
    //     return '0';
    //   case '76':
    //     return '1';
    //   case '77':
    //     return '2';
    //   case '78':
    //     return '3';
    //   case '79':
    //     return '4';
    //   case '80':
    //     return '5';
    //   case '81':
    //     return '6';
    //   case '82':
    //     return '7';
    //   case '83':
    //     return '8';
    //   case '84':
    //     return '9+';
    //   default:
    //     return null;
    // }
    //Home Exact Goals
    case '23':
    case '24':
      switch (outcomeId) {
        case '88':
          return '0';
        case '89':
          return '1';
        case '90':
          return '2';
        case '91':
          return '3';
        default:
          return null;
      }
    // Goal Range
    case '25':
      switch (outcomeId.split(':')[3]) {
        case '1121':
          return '0-1';
        case '1122':
          return '2-3';
        case '1123':
          return '4-6';
        case '1124':
          return '6+';
        case '1342':
          return '0-1';
        case '1343':
          return '2-3';
        case '1344':
          return '4-6';
        case '1345':
          return '7+';
        default:
          return outcomeId;
      }
    // Odd/Even
    case '26':
      switch (outcomeId) {
        case '70':
          return i18n.t('oddType.odd');
        case '72':
          return i18n.t('oddType.even');
        default:
          return null;
      }
    // Both Teams to Score
    case '29':
    case '33':
    case '34':
    case '48':
    case '49':
    case '50':
    case '51':
    case '56':
    case '57':
    case '58':
    case '59':
    case '75':
    case '95':
    case '112':
    case '122':
    case '341':
      switch (outcomeId) {
        case '74':
          return i18n.t('oddType.yes');
        case '76':
          return i18n.t('oddType.no');
        default:
          return null;
      }
    // Which Team to Score
    case '30':
      switch (outcomeId) {
        case '784':
          return i18n.t('oddType.noGoal');
        case '788':
          return '1';
        case '790':
          return '2';
        case '792':
          return '1X2';
        default:
          return null;
      }
    //Winner and Both Teams To Score
    case '35':
      switch (outcomeId) {
        case '78':
          return '1 ' + i18n.t('oddType.yes');
        case '80':
          return '1 ' + i18n.t('oddType.no');
        case '82':
          return 'X ' + i18n.t('oddType.yes');
        case '84':
          return 'X ' + i18n.t('oddType.no');
        case '86':
          return '2 ' + i18n.t('oddType.yes');
        case '88':
          return '2 ' + i18n.t('oddType.no');
        default:
          return null;
      }
    // Over under and both teams to score
    case '36':
      switch (outcomeId) {
        case '90':
          return '+ ' + i18n.t('oddType.yes');
        case '92':
          return '- ' + i18n.t('oddType.yes');
        case '94':
          return '+ ' + i18n.t('oddType.no');
        case '96':
          return '- ' + i18n.t('oddType.no');
        default:
          return null;
      }
    // Winner and over Under
    case '37':
      switch (outcomeId) {
        case '794':
          return '1/-';
        case '796':
          return '1/+';
        case '798':
          return 'X/-';
        case '800':
          return 'X/+';
        case '802':
          return '2/-';
        case '804':
          return '2/+';
        default:
          return null;
      }
    // First Goal Scorer
    case '38':
    case '39':
    case '40':
      if (outcomeName.includes(',')) {
        return outcomeName.split(',')[1] + ' ' + outcomeName.split(',')[0];
      } else {
        return outcomeName;
      }
    // Correct Score
    case '41':
      return outcomeName;
    // Halftime/Fulltime
    case '47':
      switch (outcomeId) {
        case '418':
          return '1/1';
        case '420':
          return '1/X';
        case '422':
          return '1/2';
        case '424':
          return 'X/1';
        case '426':
          return 'X/X';
        case '428':
          return 'X/2';
        case '430':
          return '2/1';
        case '432':
          return '2/X';
        case '434':
          return '2/2';
        default:
          return null;
      }
    //Highest scoring half
    case '52':
      switch (outcomeId) {
        case '436':
          return i18n.t('oddType.firstHalf');
        case '438':
          return i18n.t('oddType.secondHalf');
        case '440':
          return i18n.t('oddType.equal');
        default:
          return null;
      }
    // both half goal
    case '55':
      switch (outcomeId) {
        case '806':
          return i18n.t('oddType.no') + '/' + i18n.t('oddType.no');
        case '808':
          return i18n.t('oddType.yes') + '/' + i18n.t('oddType.no');
        case '810':
          return i18n.t('oddType.yes') + '/' + i18n.t('oddType.yes');
        case '812':
          return i18n.t('oddType.no') + '/' + i18n.t('oddType.yes');
        default:
          return null;
      }
    // 1st half winner
    case '60':
      switch (outcomeId) {
        case '1':
          return '1';
        case '2':
          return 'X';
        case '3':
          return '2';
        default:
          return null;
      }
    //1st Half - Rest Of The Match
    case '61':
      switch (outcomeId) {
        case '1':
          return '1';
        case '2':
          return 'X';
        case '3':
          return '2';
        default:
          return null;
      }
    // 1st half first goal
    case '62':
    case '84':
      switch (outcomeId) {
        case '6':
          return '1';
        case '7':
          return 'X';
        case '8':
          return '2';
        default:
          return null;
      }

    // 1st half draw no bet
    case '64':
      switch (outcomeId) {
        case '4':
          return '1';
        case '5':
          return '2';
        default:
          return null;
      }
    // 1st half handicap
    case '65':
      switch (outcomeId) {
        case '1711':
          return '1';
        case '1712':
          return 'X';
        case '1713':
          return '2';
        default:
          return null;
      }
    // 1st half away over/under
    case '68':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return null;
      }
    case '69':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return null;
      }
    case '70':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return null;
      }
    // 1st half exact goals && when will the 1st goal be scored 15 minutes interval
    // switch (outcomeId) {
    //   case 'sr:exact_goals:3+:88':
    //     return '0';
    //   case 'sr:exact_goals:3+:89':
    //     return '1';
    //   case 'sr:exact_goals:3+:90':
    //     return '2';
    //   case 'sr:exact_goals:3+:91':
    //     return '3+';
    //   default:
    //     return null;
    // }
    case '74':
    case '93':
      switch (outcomeId) {
        case '70':
          return i18n.t('common:odds.odd');
        case '72':
          return i18n.t('common:odds.even');
        default:
          return null;
      }
    case '100':
      switch (outcomeId) {
        case '596':
          return i18n.t('oddType.none');
        default:
          return outcomeName;
      }
    //Overtime - Winner
    case '113':
      switch (outcomeId) {
        case '1':
          return '1';
        case '2':
          return 'X';
        case '3':
          return '2';
        default:
          return null;
      }
    case '115':
      switch (outcomeId) {
        case '6':
          return '1';
        case '7':
          return 'X';
        case '8':
          return '2';
        default:
          return null;
      }
    //Corner winner
    case '162':
      switch (outcomeId) {
        case '1':
          return '1';
        case '2':
          return 'X';
        case '3':
          return '2';
        default:
          return null;
      }
    case '116':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return null;
      }
    //Over/Under Corner
    case '166':
      switch (outcomeId) {
        case '12':
          return '+';
        case '13':
          return '-';
        default:
          return null;
      }
    case '186':
      switch (outcomeId) {
        case '4':
          return '1';
        case '5':
          return '2';
        default:
          return outcomeName;
      }
    // double chance and first half double chance exact
    case '540':
    case '542':
    case '546':
      switch (outcomeId) {
        case '1718':
          return '1/X ' + i18n.t('oddType.yes');
        case '1719':
          return '1/X ' + i18n.t('oddType.no');
        case '1720':
          return '1/2 ' + i18n.t('oddType.yes');
        case '1721':
          return '1/2 ' + i18n.t('oddType.no');
        case '1722':
          return 'X/2 ' + i18n.t('oddType.yes');
        case '1723':
          return 'X/2 ' + i18n.t('oddType.no');
        default:
          return null;
      }
    // double chance and over under
    case '547':
      switch (outcomeId) {
        case '1724':
          return '1X -';
        case '1725':
          return '12 -';
        case '1726':
          return 'X2 -';
        case '1727':
          return '1X +';
        case '1728':
          return '12 +';
        case '1729':
          return 'X2 +';
        default:
          return null;
      }
    case '551':
      switch (outcomeId) {
        case '1803':
          return i18n.t('oddType.draw');
        case '1750':
          return '1:0, 2:0 or 3:0';
        case '1751':
          return '0:1, 0:2 or 0:3';
        case '1752':
          return '4:0, 5:0 or 6:0';
        case '1753':
          return '0:4, 0:5 or 0:6';
        case '1754':
          return '2:1, 3:1 or 4:1';
        case '1755':
          return '1:2, 1:3 or 1:4';
        case '1756':
          return '3:2, 4:2, 4:3 or 5:1';
        case '1757':
          return '2:3, 2:4, 3:4 or 1:5';
        case '1758':
          return i18n.t('oddType.otherHomeWin');
        case '1759':
          return i18n.t('oddType.otherAwayWin');
        default:
          return null;
      }
    case '552':
      switch (outcomeId) {
        case '1805':
          return i18n.t('oddType.noGoal');
        case '1746':
          return '1-2';
        case '1747':
          return '1-3';
        case '1748':
          return '2-3';
        case '1749':
          return '4+';
        default:
          return null;
      }
    case '818':
    case '819':
      switch (outcomeId) {
        case '1836':
          return '1/1 -';
        case '1837':
          return '1/X -';
        case '1838':
          return '1/2 -';
        case '1839':
          return 'X/1 -';
        case '1840':
          return 'X/X -';
        case '1841':
          return 'X/2 -';
        case '1842':
          return '2/1 -';
        case '1843':
          return '2/X -';
        case '1844':
          return '2/2 -';
        case '1845':
          return '1/1 +';
        case '1846':
          return '1/X +';
        case '1847':
          return '1/2 +';
        case '1848':
          return 'X/1 +';
        case '1849':
          return 'X/X +';
        case '1850':
          return 'X/2 +';
        case '1851':
          return '2/1 +';
        case '1852':
          return '2/X +';
        case '1853':
          return '2/2 +';
        default:
          return outcomeName;
      }
    case '820':
      switch (outcomeId) {
        case '1854':
          return 'X/X 0';
        case '1855':
          return '1/1 1';
        case '1856':
          return 'X/1 1';
        case '1857':
          return 'X/2 1';
        case '1858':
          return '2/2 1';
        case '1859':
          return '1/1 2';
        case '1860':
          return '1/X 2';
        case '1861':
          return 'X/1 2';
        case '1862':
          return 'X/X 2';
        case '1863':
          return 'X/2 2';
        case '1864':
          return '2/X 2';
        case '1865':
          return '2/2 2';
        case '1866':
          return '1/1 3';
        case '1867':
          return '1/2 3';
        case '1868':
          return 'X/1 3';
        case '1869':
          return 'X/2 3';
        case '1870':
          return '2/X 3';
        case '1871':
          return '2/2 3';
        case '1872':
          return '1/1 4';
        case '1873':
          return '1/X 4';
        case '1874':
          return '1/2 4';
        case '1875':
          return 'X/1 4';
        case '1876':
          return 'X/X 4';
        case '1877':
          return 'X/2 4';
        case '1878':
          return '2/1 4';
        case '1879':
          return '2/X 4';
        case '1880':
          return '2/2 4';
        case '1881':
          return '1/1 5+';
        case '1882':
          return '1/X 5+';
        case '1883':
          return '1/2 5+';
        case '1884':
          return 'X/1 5+';
        case '1885':
          return 'X/X 5+';
        case '1886':
          return 'X/2 5+';
        case '1887':
          return '2/1 5+';
        case '1888':
          return '2/X 5+';
        case '1889':
          return '2/2 5+';
        default:
          return null;
      }
    // goalscorer
    case '888':
    case '891':
      switch (outcomeId) {
        case '1894':
          return '1';
        case '1895':
          return '0';
        case '1896':
          return '2';
        case '1897':
          return i18n.t('oddType.other');
        default:
          return outcomeName;
      }
    //Live market next goal scorer
    case '896':
      switch (outcomeId) {
        case '1937':
          return i18n.t('oddType.shot');
        case '1938':
          return i18n.t('oddType.header');
        case '1939':
          return i18n.t('oddType.ownGoal');
        case '1940':
          return i18n.t('oddType.penalty');
        case '1941':
          return i18n.t('oddType.freeKick');
        case '1942':
          return i18n.t('oddType.none');
        default:
          return i18n.t(outcomeName);
      }
    default:
      return i18n.t(outcomeName);
  }
}

export function getSportTypeToIconForBetSlip(sportType) {
  switch (sportType) {
    case 0:
      return <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/football.svg`} />;
    case 1:
      return <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/basketball.svg`} />;
    case 2:
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/tennis.svg`} />;
    case 3:
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/american-football.svg`} />;
    case 4:
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/baseball.svg`} />;
    case 5:
      return <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/cricket.svg`} />;
    case 9:
      return <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/82.png`} />;
    case 6:
      return <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/88.png`} />;
    case 8:
      return <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/79.png`} />;
    case 10:
      return <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/89.png`} />;
    case 11:
      return <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/77.png`} />;
    case 12:
      return <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/92.png`} />;
    case 13:
      return <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/86.png`} />;
    case 7:
      return <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/87.png`} />;
    case 14:
      return <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/84.png`} />;
    case 16:
      return <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/85.png`} />;
    case 18:
      return <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/90.png`} />;
    case 17:
      return <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/91.png`} />;
    default:
      return <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/87.png`} />;
  }
}

export function getSportTypeToActiveIcon(sportType) {
  switch (sportType) {
    case '0':
    case 'Football':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/football.svg`} />;
    case '1':
    case 'Basketball':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/basketball.svg`} />;
    case '2':
    case 'Tennis':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/tennis.svg`} />;
    case '3':
    case 'AmericanFootball':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/american-football.svg`} />;
    case '4':
    case 'Baseball':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/baseball.svg`} />;
    case '5':
    case 'Cricket':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/cricket.svg`} />;
    case '6':
    case 'Darts':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/darts.svg`} />;
    case '7':
    case 'Futsal':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/futsal.svg`} />;
    case '8':
    case 'Handball':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/handball.svg`} />;
    case '9':
    case 'IceHockey':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/iceHockey.svg`} />;
    case '10':
    case 'Rugby':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/rugby.svg`} />;
    case '11':
    case 'Snooker':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/snooker.svg`} />;
    case '12':
    case 'TableTennis':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/tabletennis.svg`} />;
    case '13':
    case 'Volleyball':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/volleyball2.svg`} />;
    case '14':
    case 'Boxing':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/boxing.svg`} />;
    case '16':
    case 'CounterStrike':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/counterstrike.svg`} />;
    case '17':
    case 'Dota2':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/dota2.svg`} />;
    case '18':
    case 'LeagueOfLegends':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/leagueoflegends.svg`} />;
    default:
      return null;
  }
}

export function getSportTypeToGreenIcon(sportType) {
  switch (sportType) {
    case '0':
    case 'Football':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/300.svg`} />;
    case '1':
    case 'Basketball':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/301.svg`} />;
    case '2':
    case 'Tennis':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/302.svg`} />;
    case '3':
    case 'AmericanFootball':
      return (
        <img
          className="icon-color"
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/american-football.svg`}
        />
      );
    case '4':
    case 'Baseball':
      return (
        <img
          className="icon-color"
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/baseball.svg`}
        />
      );
    case '5':
    case 'Cricket':
      return (
        <img
          className="icon-color"
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/cricket.svg`}
        />
      );
    case '6':
    case 'Darts':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/306.svg`} />;
    case '7':
    case 'Futsal':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/307.svg`} />;
    case '8':
    case 'Handball':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/308.svg`} />;
    case '9':
    case 'IceHockey':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/309.svg`} />;
    case '10':
    case 'Rugby':
      return (
        <img
          className="icon-color"
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/rugby.svg`}
        />
      );
    case '11':
    case 'Snooker':
      return (
        <img
          className="icon-color"
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/snooker.svg`}
        />
      );
    case '12':
    case 'TableTennis':
      return (
        <img
          className="icon-color"
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/tabletennis.svg`}
        />
      );
    case '13':
    case 'Volleyball':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/313.svg`} />;
    case '14':
    case 'Boxing':
      return (
        <img
          className="icon-color"
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/boxing.svg`}
        />
      );
    case '16':
    case 'CounterStrike':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/counterstrike.svg`} />;
    case '17':
    case 'Dota2':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/dota2.svg`} />;
    case '18':
    case 'LeagueOfLegends':
      return (
        <img
          className="icon-color"
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/leagueoflegends.svg`}
        />
      );
    default:
      return null;
  }
}

export function getSportTypeToWhiteIcon(sportType) {
  switch (sportType) {
    case '0':
    case 'Football':
      return (
        <img
          className="icon-color"
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/football.svg`}
        />
      );
    case '1':
    case 'Basketball':
      return (
        <img
          className="icon-color"
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/basketball.svg`}
        />
      );
    case '2':
    case 'Tennis':
      return (
        <img
          className="icon-color"
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/tennis.svg`}
        />
      );
    case '3':
    case 'AmericanFootball':
      return (
        <img
          className="icon-color"
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/american-football.svg`}
        />
      );
    case '4':
    case 'Baseball':
      return (
        <img
          className="icon-color"
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/baseball.svg`}
        />
      );
    case '5':
    case 'Cricket':
      return (
        <img
          className="icon-color"
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/cricket.svg`}
        />
      );
    case '6':
    case 'Darts':
      return (
        <img
          className="icon-color"
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/darts.svg`}
        />
      );
    case '7':
    case 'Futsal':
      return (
        <img
          className="icon-color"
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/futsal.svg`}
        />
      );
    case '8':
    case 'Handball':
      return (
        <img
          className="icon-color"
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/handball.svg`}
        />
      );
    case '9':
    case 'IceHockey':
      return (
        <img
          className="icon-color"
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/iceHockey.svg`}
        />
      );
    case '10':
    case 'Rugby':
      return (
        <img
          className="icon-color"
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/rugby.svg`}
        />
      );
    case '11':
    case 'Snooker':
      return (
        <img
          className="icon-color"
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/snooker.svg`}
        />
      );
    case '12':
    case 'TableTennis':
      return (
        <img
          className="icon-color"
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/tabletennis.svg`}
        />
      );
    case '13':
    case 'Volleyball':
      return (
        <img
          className="icon-color"
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/volleyball2.svg`}
        />
      );
    case '14':
    case 'Boxing':
      return (
        <img
          className="icon-color"
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/boxing.svg`}
        />
      );
    case '16':
    case 'CounterStrike':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/counterstrike.svg`} />;
    case '17':
    case 'Dota2':
      return <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/dota2.svg`} />;
    case '18':
    case 'LeagueOfLegends':
      return (
        <img
          className="icon-color"
          width="25"
          height="25"
          src={`${AWS_BUCKET_URL}/Icons/leagueoflegends.svg`}
        />
      );
    default:
      return null;
  }
}

export function gameDay(date) {
  const day = date.split('T')[0].split('-');
  return `${day[2]}.${day[1]}`;
}

export function gameTime(date) {
  date = new Date(date).toLocaleString();
  const time = date.split(' ')[1].split(':');
  //const time = date.split('T')[1].split('+')[0].split(':');
  return `${time[0]}:${time[1]}`;
}

export function ReverseName(name) {
  if (name.includes(',')) {
    const names = name.split(',');
    return names[1] + ' ' + names[0];
  }
  return name;
}

export function OutcomeName(marketId, stringId, marketName) {
  var marketNameText = '';
  if (stringId.includes('-S:')) {
    if (stringId.split('-S:')[1].includes('sr')) {
      if (
        marketId == EnumMarketName.EXACTGOALS_21 ||
        marketId == EnumMarketName.GOALRANGE_25 ||
        marketId == EnumMarketName.FIRSTHALFEXACTGOALS_71
      ) {
        marketNameText = stringId.split(':')[3];
        return i18n.t(`markets.${marketId}`) + ' - ' + marketNameText;
      }
      return i18n.t(`markets.${marketId}`);
    }
    if (
      marketId == EnumMarketName.ROM_7 ||
      marketId == EnumMarketName.NEXTGOALSCORER_38 ||
      marketId == EnumMarketName.FIRSTHALFNEXTGOAL_62 ||
      marketId == EnumMarketName.LASTGOALSCORER_39 ||
      marketId == EnumMarketName.ANYTIMEGOALSCORER_40 ||
      marketId == EnumMarketName.CORRECTSCORE_41 ||
      marketId == EnumMarketName.CORRECTSCORE_199 ||
      marketId == EnumMarketName.NEXTSCORINGTYPE_896 ||
      marketId == EnumMarketName.OVERTIMENEXTGOAL_115 ||
      marketId == EnumMarketName.FIRSTHALFROM_61 ||
      marketId == EnumMarketName.OVERTIMERoM_114
    ) {
      return i18n.t(`markets.${marketId}`);
    } else if (
      marketId == EnumMarketName.FIRSTHALFOVERUNDER_68 ||
      marketId == EnumMarketName.FIRSTHALFHANDICAP_65 ||
      marketId == EnumMarketName.FIRSTHALFHOMEOVERUNDER_69 ||
      marketId == EnumMarketName.FIRSTHALFAWAYOVERUNDER_70 ||
      marketId == EnumMarketName.HOMETOTAL_19 ||
      marketId == EnumMarketName.AWAYTOTAL_20 ||
      marketId == EnumMarketName.TOTAL_18 ||
      marketId == EnumMarketName.HANDICAP_14 ||
      marketId == EnumMarketName.DOUBLECHANCEOVERUNDER_547 ||
      marketId == EnumMarketName.OVERUNDERCORNERS_166 ||
      marketId == EnumMarketName.TOTALGAMES_189 ||
      marketId == EnumMarketName.WINNERANDOVERUNDER_37 ||
      marketId == EnumMarketName.TOTAL_225 ||
      marketId == EnumMarketName.TOTAL_228 ||
      marketId == EnumMarketName.TOTALPOINT_238 ||
      marketId == EnumMarketName.TOTALFRAMES_494 ||
      marketId == EnumMarketName.SECONDHALFOVERUNDER_90 ||
      marketId == EnumMarketName.SECONDHALFHOMEOVERUNDER_91 ||
      marketId == EnumMarketName.SECONDHALFAWAYOVERUNDER_92 ||
      marketId == EnumMarketName.HOMEOVERUNDER_227 ||
      marketId == EnumMarketName.AWAYOVERUNDER_228
    ) {
      marketNameText = stringId.split('-S:')[1];
      return marketNameText;
    } else if (
      marketId == EnumMarketName.HOMEOVERUNDER_756 ||
      marketId == EnumMarketName.AWAYOVERUNDER_757
    ) {
      return stringId.split('-S:')[2];
    } else if (
      marketId == EnumMarketName.GAMEHANDICAP_187 ||
      marketId == EnumMarketName.HANDICAP_223 ||
      marketId == EnumMarketName.HANDICAP_256
    ) {
      marketNameText = stringId;
      // if (stringId.includes('-S:-')) {
      //   marketNameText = stringId.split('-')[3];
      // } else {
      //   marketNameText = stringId.split(':')[1];
      // }
      if (stringId.split('-S:')[1].includes('-')) {
        marketNameText = '0:' + stringId.split('-S:')[1].slice(1);
      } else {
        marketNameText = stringId.split('-S:')[1] + ':0';
      }
      return marketNameText;
    } else if (marketId == EnumMarketName.NEXTGOAL_8) {
      marketNameText = stringId.split('-S:')[1];
      return i18n.t(`markets.${marketId}`) + ' ' + marketNameText;
    } else if (
      marketId == EnumMarketName.FIRSTHALFHANDICAP_66 ||
      marketId == EnumMarketName.POINTHANDICAP_237 ||
      marketId == EnumMarketName.HANDICAP_16
    ) {
      marketNameText = stringId;
      if (stringId.includes('-S:-')) {
        marketNameText = '0:' + stringId.split('-S:-')[1];
      } else {
        marketNameText = stringId.split(':')[1] + ':0';
      }
      return marketNameText;
    } else if (marketId == EnumMarketName.FRAMEHANDICAP_493) {
      if (stringId.includes('-S:-')) {
        marketNameText = '0:' + stringId.split(':-')[1];
      } else {
        marketNameText = stringId.split(':')[1] + ':0';
      }
      return marketNameText;
    } else if (marketId == EnumMarketName.SETHANDICAP_188) {
      if (stringId.includes('-S:-')) {
        marketNameText = '0:' + stringId.split(':-')[1];
      } else {
        marketNameText = stringId.split(':')[1] + ':0';
      }
      return i18n.t(`markets.${marketId}`) + ' ' + marketNameText;
    } else if (marketId == EnumMarketName.INNINGSTOTAL_605) {
      marketNameText = stringId.split('-S:1-S:')[1];
      return i18n.t(`markets.${marketId}`) + ' ' + marketNameText;
    } else if (marketId == EnumMarketName.FRAMEWINNER_499) {
      marketNameText = stringId.split('-S:')[1];
      return marketNameText + i18n.t(`markets.${marketId}`) + ' ' + marketNameText;
    } else if (marketId === EnumMarketName.QUARTEROVERUNDER_236) {
      const splittedMarket = stringId.split('-S:');
      marketNameText = splittedMarket[1];
      return (
        'Q' +
        marketNameText +
        '. ' +
        i18n.t(`markets.${marketId}`).split('-')[1] +
        ' ' +
        splittedMarket[2]
      );
    } else if (marketId === EnumMarketName.QUARTERHANDICAP_303) {
      const splittedMarket = stringId.split('-S:');
      if (stringId.includes('-S:-')) {
        marketNameText = '0:' + splittedMarket[1].slice(1);
      } else {
        marketNameText = stringId.split(':')[1] + ':0';
      }
      return (
        'Q' +
        splittedMarket[2] +
        '. ' +
        i18n.t(`markets.${marketId}`).split(' ')[1] +
        ' ' +
        marketNameText
      );
    } else if (marketId === EnumMarketName.OVERUNDERANDSETWINNER_1056) {
      return (
        stringId.split('-S:')[1] +
        '.' +
        i18n.t(`markets.${marketId}`) +
        ' ' +
        stringId.split('-S:')[2]
      );
    } else if (marketId === EnumMarketName.TOTALGAMESSET_204) {
      return (
        stringId.split('-S:')[1] +
        '.' +
        i18n.t(`markets.${marketId}`) +
        ' ' +
        stringId.split('-S:')[2]
      );
    } else if (marketId === EnumMarketName.SETWINNER_202) {
      return stringId.split('-S:')[1] + '.' + i18n.t(`markets.${marketId}`);
    } else if (
      marketId === EnumMarketName.QUARTERWINNER_235 ||
      marketId === EnumMarketName.HOMEOVERUNDER_756 ||
      marketId === EnumMarketName.AWAYOVERUNDER_757
    ) {
      return 'Q' + stringId.split('-S:')[1] + ' ' + i18n.t(`markets.${marketId}`);
    } else {
      marketNameText = stringId.split('-S:')[1];
      return i18n.t(`markets.${marketId}`) + ' ' + marketNameText;
    }
  } else {
    const name = i18n.t(`markets.${marketId}`);
    if (name.includes('market')) {
      return marketName;
    } else {
      return name;
    }
  }
}

export function getMatchTime(match, t) {
  if (match.sport !== sports.Football) {
    return (
      <span className="time">
        {t(`components.matchcard.time.byMatchStatusId.${match.matchStatusId}`)}
      </span>
    );
  }
  const getFootballLiveEventTime = (match) => {
    if (match.sport !== sports.Football) {
      throw new Error('This function is only for football matches');
    }

    const matchStatusId = match.matchStatusId;

    if (
      matchStatusId === EnumMatchStatusName.HALFTIME ||
      matchStatusId === EnumMatchStatusName.FIRST_BREAK ||
      matchStatusId === EnumMatchStatusName.EXTRA_TIME_HALFTIME
    ) {
      return t('components.matchcard.halftime');
    }

    if (matchStatusId === EnumMatchStatusName.PENALTIES) {
      return t('components.matchcard.penalties');
    }

    if (matchStatusId === EnumMatchStatusName.ENDED) {
      return t('components.matchcard.end');
    }

    if (
      matchStatusId === EnumMatchStatusName.FIRST_HALF ||
      matchStatusId === EnumMatchStatusName.SECOND_HALF ||
      matchStatusId === EnumMatchStatusName.FIRST_EXTRA ||
      matchStatusId === EnumMatchStatusName.SECOND_EXTRA
    ) {
      if (Number.isNaN(match.timeTextInternal) || Number.isNaN(match.timeTextStoppageInternal)) {
        return '-';
      }
      const matchTimeTextLimitsInMinutes = {
        [EnumMatchStatusName.FIRST_HALF]: 45,
        [EnumMatchStatusName.SECOND_HALF]: 90,
        [EnumMatchStatusName.FIRST_EXTRA]: 105,
        [EnumMatchStatusName.SECOND_EXTRA]: 120
      };

      // Match time always has to be at least 1 minute, to show match is in the first minute
      const matchTimeLimitInSeconds = Math.max(
        (matchTimeTextLimitsInMinutes[matchStatusId] ?? 150) * 60,
        60
      );

      const stoppageTimeLimitInSeconds = 45 * 60;

      let hasValidStoppageTime = match.timeTextInternal >= matchTimeLimitInSeconds;

      const timeTextInMinutes = Math.ceil(
        Math.min(match.timeTextInternal, matchTimeLimitInSeconds) / 60
      ).toFixed(0);

      const stoppageTimeInMinutes = hasValidStoppageTime
        ? Math.ceil(
            Math.min(match.timeTextStoppageInternal, stoppageTimeLimitInSeconds) / 60
          ).toFixed(0)
        : '0';

      // we don't want to show 45+0' so wait a bit for the stoppage time to be added
      hasValidStoppageTime = hasValidStoppageTime && stoppageTimeInMinutes !== '0';

      return (
        <span>
          {timeTextInMinutes}
          {hasValidStoppageTime ? <sup>+{stoppageTimeInMinutes}&apos;</sup> : <>&apos;</>}
        </span>
      );
    }

    return t(`time.byMatchStatusId.${matchStatusId}`);
  };

  return <span className="time">{getFootballLiveEventTime(match)}</span>;
}

export function sportTypeToSportId(sportType) {
  switch (sportType) {
    case 'Football':
      return 0;
    case 'Basketball':
      return 1;
    case 'Tennis':
      return 2;
    case 'AmericanFootball':
      return 3;
    case 'Baseball':
      return 4;
    case 'Cricket':
      return 5;
    case 'Darts':
      return 6;
    case 'Futsal':
      return 7;
    case 'Handball':
      return 8;
    case 'IceHockey':
      return 9;
    case 'Rugby':
      return 10;
    case 'Snooker':
      return 11;
    case 'TableTennis':
      return 12;
    case 'Volleyball':
      return 13;
    case 'Boxing':
      return 14;
    case 'CounterStrike':
      return 16;
    case 'Dota2':
      return 17;
    case 'LeagueOfLegends':
      return 18;
    default:
      return null;
  }
}

export function sportIdToSportType(sportId) {
  switch (sportId) {
    case '0':
      return 'Football';
    case '1':
      return 'Basketball';
    case '2':
      return 'Tennis';
    case '3':
      return 'AmericanFootball';
    case '4':
      return 'Baseball';
    case '5':
      return 'Cricket';
    case '6':
      return 'Darts';
    case '7':
      return 'Futsal';
    case '8':
      return 'Handball';
    case '9':
      return 'IceHockey';
    case '10':
      return 'Rugby';
    case '11':
      return 'Snooker';
    case '12':
      return 'TableTennis';
    case '13':
      return 'Volleyball';
    case '14':
      return 'Boxing';
    case 'CounterStrike':
      return 16;
    case '17':
      return 'Dota2';
    case '18':
      return 'LeagueOfLegends';
    default:
      return null;
  }
}

export const emptyMarketLabels = (marketId) => {
  const defaultMarketObject = {
    1: ['1', 'X', '2'],
    7: ['1', 'X', '2'],
    8: ['1', 'X', '2'],
    10: ['1X', '12', 'X2'],
    14: ['1', 'X', '2'],
    16: ['1', '2'],
    18: ['+', '-'],
    29: ['Yes', 'No'],
    186: ['1', '2'],
    187: ['1', '2'],
    188: ['1', '2'],
    189: ['+', '-'],
    202: ['1', '2'],
    219: ['1', '2'],
    223: ['1', '2'],
    225: ['+', '-'],
    237: ['1', '2'],
    238: ['+', '-'],
    251: ['1', '2'],
    256: ['1', '2'],
    258: ['+', '-'],
    314: ['+', '-'],
    756: ['+', '-'],
    757: ['+', '-']
  };
  return defaultMarketObject[marketId];
};

export const categoryNameHelper = (category, categoryNames, categoryText) => {
  if (`categories.${category}` === categoryText && categoryNames) {
    categoryText = categoryNames[category];
  }
  return categoryText;
};

// export const tournamentNameHelper = (
//   tournament,
//   sports,
//   sport,
//   events,
//   type,
//   renderedGroups,
//   tournamentText,
//   tournaments
// ) => {
//   if (`tournaments.${tournament}` === tournamentText) {
//     if (type == 'matchContainer') {
//       const sportGroups = sports[sport];
//       if (sportGroups) {
//         for (const group of sportGroups) {
//           if (events[group.matches[0]]?.leagueId === tournament) {
//             tournamentText = events[group.matches[0]].league;
//             break;
//           }
//         }
//       }
//     } else {
//       if (Array.isArray(renderedGroups[sport])) {
//         for (const group of renderedGroups[sport]) {
//           if (events[group.matches[0]]?.leagueId === tournament) {
//             tournamentText = events[group.matches[0]].league;
//             console.log(events[group.matches[0]].league);
//             break;
//           }
//         }
//       }
//       const fallbackText = `tournaments.${tournament}`;
//       if (
//         fallbackText === tournamentText &&
//         tournaments &&
//         tournaments[tournament] !== fallbackText
//       ) {
//         tournamentText = tournaments[tournament];
//       }
//     }
//   }
//   return tournamentText;
// };

export const tournamentNameHelper = (tournamentId, tournamentNames, t) => {
  const tournamentSelector = `tournaments.${tournamentId}`;
  const tournamentName = t(tournamentSelector);
  if (tournamentName === tournamentSelector) {
    return tournamentNames[tournamentId];
  }
  return tournamentName;
};

export const checkIfLiveEvent = (match) => {
  const isChecked = match?.markets?.some((m) => m.producer === 2);
  if (isChecked === undefined) {
    return false;
  }
  return isChecked;
};

export const composeEventName = (event, locales, locale, t) => {
  const home = event?.match ? event?.match?.competitors[0] : event?.competitors[0];
  const away = event?.match ? event?.match?.competitors[1] : event?.competitors[1];
  return `${getTeamNameHelper(
    locales,
    locale,
    home,

    t
  )} vs ${getTeamNameHelper(locales, locale, away, t)}`;
};
export const composeMarketAndOutcomeText = (market, outcomeId, t) => {
  const marketName = MarketIdToMarketName(market.id, market.stringId, t, '');

  const outcome = market?.outcomes.find((o) => o.id === outcomeId);

  const outcomeName = outcome
    ? getFootballOddTypeText(market.id, outcomeId, outcome.name, t)
    : 'No Outcome Name';

  return `${marketName} - ${outcomeName}`;
};

export const parsePlaceBetResponseMsg = (
  responseMsg,
  t,
  currencyFormatter,
  getSlipEvent,
  locales,
  locale
) => {
  const code = responseMsg.code;

  switch (code) {
    case 'B001': {
      const limit = currencyFormatter.format(responseMsg.extraParam1 ?? 0);
      const stake = currencyFormatter.format(responseMsg.extraParam2 ?? 0);
      return t(`common.messages.coded.${code}`, { limit, stake });
    }
    case 'B002':
    case 'B011': {
      const limit = currencyFormatter.format(responseMsg.extraParam1 ?? 0);
      return t(`common.messages.coded.${code}`, { limit });
    }
    case 'B002-2':
      return t(`common.messages.coded.${code}`);
    case 'B016':
    case 'B017':
    case 'B018':
    case 'B018-2':
    case 'B019': {
      const eventId = responseMsg.extraParam1;

      if (!eventId) {
        return t(`common.messages.coded.${code}`, {
          game: 'No Event Name',
          limit: '0'
        });
      }
      const limit = currencyFormatter.format(responseMsg.extraParam2 ?? 0);
      const betSlipOutcomeEntry = getSlipEvent(eventId);

      const game = betSlipOutcomeEntry
        ? composeEventName(betSlipOutcomeEntry, locales, locale, t)
        : 'No Event Name';
      return t(`common.messages.coded.${code}`, { game, limit });
    }
    case 'B404':
    case 'B405':
    case 'BPb_001':
    case 'BPb_002':
    case 'BPb_003':
    case 'BPb_004':
    case 'BPb_005':
    case 'BPb_006': {
      const eventId = responseMsg.extraParam1;
      const marketId = responseMsg.extraParam2;
      const outcomeId = responseMsg.extraParam3;

      if (!eventId || !marketId || !outcomeId) {
        return t(`common.messages.coded.${code}`, {
          eventAndMarket: 'No Event Name, No Market Name'
        });
      }
      const betSlipOutcomeEntry = getSlipEvent(eventId);
      const market = betSlipOutcomeEntry?.markets.find((x) => x.stringId === marketId);

      const eventName = betSlipOutcomeEntry
        ? composeEventName(betSlipOutcomeEntry, locales, locale, t)
        : 'No Event Name';

      const marketName = market
        ? composeMarketAndOutcomeText(market, outcomeId, t)
        : 'No Market Name';

      return t(`common.messages.coded.${code}`, {
        eventAndMarket: eventName + ', ' + marketName
      });
    }

    default:
      return t('common.messages.coded.Bxxx', { reason: responseMsg.text });
  }
};

export const currencyFormatter = (currencyCode = 'EUR', numberOfDigit = 2) => {
  const _formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: currencyCode,
    maximumFractionDigits: numberOfDigit,
    minimumFractionDigits: numberOfDigit
  });

  const format = (value) => {
    if (typeof value === 'string') {
      const _value = parseFloat(value.replace(',', ''));
      if (isNaN(_value)) {
        return 'parsing error';
      }
      return _formatter.format(_value);
    } else {
      return _formatter.format(Number(value));
    }
  };
  return Object.freeze({
    format
  });
};

export const isToday = (date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const calculateTimeParameter = (timeText) => {
  switch (timeText) {
    case 'Next 4 Hours':
      return 4;
    case 'Today':
      return 1;
    case 'Tomorrow':
      return 2;
    case 'Weekend':
      return 3;
    case 'Upcoming Live Matches':
      return 5;
    default:
      return null;
  }
};

export const desktopDefaultMarkets = {
  0: [1, 18, 8],
  1: [219, 225, 223],
  2: [186, 189, 187],
  3: [219, 223, 225],
  4: [251, 258, 256],
  5: [340, 605],
  6: [186, 314, 188],
  7: [1, 18],
  8: [1, 18, 16],
  9: [1, 18, 14],
  10: [1, 18, 16],
  11: [186, 494, 493],
  12: [186, 238, 237],
  13: [186, 238, 202],
  14: [186, 18]
};

export const getDeviceType = (allMobileCheck = false) => {
  if (allMobileCheck) {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
      return 'mobile';
    } else {
      return 'desktop';
    }
  }
  if (/Android/i.test(navigator.userAgent)) {
    return 'mobile';
  } else if (/iPhone/i.test(navigator.userAgent)) {
    return 'iphone';
  } else {
    return 'desktop';
  }
};

export const eventTimeToSeconds = (time) => {
  if (!time.trim()) return 0;
  const [minutes, seconds] = time.split(':');
  const timeInSeconds = Number(minutes) * 60 + Number(seconds);

  if (Number.isNaN(timeInSeconds) || timeInSeconds < 0) {
    return 0;
  }

  return timeInSeconds;
};
