import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

// Home
import Home from 'views/home';
// Sport
import Sport from 'views/sport';
import SportDetails from 'views/sport/SportDetails';
import SportDetailsMatches from 'views/sport/SportDetailsMatches';
// Live
import Live from 'views/live/live.js';
import Profile from 'views/profile/profile';
import MyBets from 'views/mybets/mybets';
// Auth
import Register from 'views/auth/register';
import Login from 'views/auth/login';
// Profile
import Calendar from 'views/profile/calendar';
import BettingHistory from 'views/profile/bettingHistory/BettingHistory.js';
import BettingHistoryDetail from 'components/general/betting_history_detail';
import Bank from 'views/profile/bank';
import Transactions from 'views/profile/transactions.js';
import ConsentHistory from 'views/profile/consenthistory.js';
import Payout from 'views/profile/payout.js';
import Contact from 'views/profile/contact';
import ProfileSettings from 'views/profile/profile_settings';
import ChangePassword from 'views/profile/change_password';
import EditProfile from 'views/profile/edit_profile';
import UploadDocument from 'views/profile/upload_document';
import ResponsibleGaming from 'views/profile/responsible_gaming';
import MatchDetail from 'components/general/match_detail';
import BetSlipList from 'views/betslip/bet_slip';
import Search from 'components/search/search';
// Results
import Results from 'views/results';
import ResultsDetail from 'views/results/ResultsDetails';
// Secondary
import TopMatches from 'views/secondary/TopMatches';
import ClearFavorites from 'views/secondary/ClearFavorites';
// import AutomaticBets from 'views/secondary/AutomaticBets';
// Slot
import Slot from 'views/slot/Slot';
import SlotGame from 'views/slot/slot_game';
import Casino from 'views/slot/casino';
// Contact
import Overview from 'views/contact/aboutUs/overview';
import TermsAndConditions from 'views/contact/termAndConditions/termsAndConditions';
import PrivacyPolicy from 'views/contact/privacyPolicy/privacyPolicy';
import LegalNote from 'views/contact/legalNote/legalNote';
import Support from 'views/contact/support/support';
// Information
import Help from 'views/information/help/help';
import BettingRules from 'views/information/bettingRules';
import QRDeposit from 'views/profile/qrDeposit';

function RequireAuth({ children }) {
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

const routes = [
  {
    path: '/search',
    element: (
      <RequireAuth>
        <Search />
      </RequireAuth>
    )
  },
  {
    path: '/sport',
    title: 'Sport Page',
    element: (
      <RequireAuth>
        <Sport />
      </RequireAuth>
    )
  },
  {
    path: '/sport-details/:sportType?',
    element: (
      <RequireAuth>
        <SportDetails />
      </RequireAuth>
    )
  },
  {
    path: '/sport-details/:sportType/matches',
    element: (
      <RequireAuth>
        <SportDetailsMatches />
      </RequireAuth>
    )
  },
  {
    path: '/about-us',
    element: (
      <RequireAuth>
        <Overview />
      </RequireAuth>
    )
  },
  {
    path: '/instructions-help/faq',
    element: (
      <RequireAuth>
        <Help />
      </RequireAuth>
    )
  },
  {
    path: '/terms-and-conditions',
    element: (
      <RequireAuth>
        <TermsAndConditions />
      </RequireAuth>
    )
  },
  {
    path: '/privacy',
    element: (
      <RequireAuth>
        <PrivacyPolicy />
      </RequireAuth>
    )
  },
  {
    path: '/legal-note',
    element: (
      <RequireAuth>
        <LegalNote />
      </RequireAuth>
    )
  },
  {
    path: '/contact',
    element: (
      <RequireAuth>
        <Support />
      </RequireAuth>
    )
  },
  {
    path: '/betting-rules',
    element: (
      <RequireAuth>
        <BettingRules />
      </RequireAuth>
    )
  },
  {
    path: '/live',
    title: 'Live Page',
    element: (
      <RequireAuth>
        <Live />
      </RequireAuth>
    )
  },
  {
    path: '/login',
    title: 'Login Page',
    element: <Login />
  },
  {
    path: '/register',
    title: 'Register Page',
    element: <Register />
  },
  {
    path: '/mybets',
    element: (
      <RequireAuth>
        <MyBets />
      </RequireAuth>
    )
  },
  {
    path: '/slot',
    title: 'Slot',
    element: (
      <RequireAuth>
        <Slot />
      </RequireAuth>
    )
  },
  {
    path: '/casino',
    title: 'Casino',
    element: (
      <RequireAuth>
        <Casino />
      </RequireAuth>
    )
  },
  {
    path: '/slot-game',
    title: 'Slot Game',
    element: (
      <RequireAuth>
        <SlotGame />
      </RequireAuth>
    )
  },
  {
    path: '/profile',
    title: 'My Profile',
    element: (
      <RequireAuth>
        <Profile />
      </RequireAuth>
    )
  },
  {
    path: '/calendar',
    element: (
      <RequireAuth>
        <Calendar />
      </RequireAuth>
    )
  },
  {
    path: '/bettinghistory',
    element: (
      <RequireAuth>
        <BettingHistory />
      </RequireAuth>
    )
  },
  {
    path: '/bettinghistorydetail/:slipId?',
    element: (
      <RequireAuth>
        <BettingHistoryDetail />
      </RequireAuth>
    )
  },
  {
    path: '/bank',
    element: (
      <RequireAuth>
        <Bank />
      </RequireAuth>
    )
  },
  {
    path: '/transactions/:customerId',
    element: (
      <RequireAuth>
        <Transactions />
      </RequireAuth>
    )
  },
  {
    path: '/payout',
    element: (
      <RequireAuth>
        <Payout />
      </RequireAuth>
    )
  },
  {
    path: '/consenthistory',
    element: (
      <RequireAuth>
        <ConsentHistory />
      </RequireAuth>
    )
  },
  {
    path: '/clear-favorites',
    element: (
      <RequireAuth>
        <ClearFavorites />
      </RequireAuth>
    )
  },
  {
    path: '/top-matches',
    element: (
      <RequireAuth>
        <TopMatches />
      </RequireAuth>
    )
  },
  // {
  //   path: '/automatic-bets',
  //   element: <AutomaticBets />
  // },
  {
    path: '/results/:sportTypeMenuVisible?',
    element: (
      <RequireAuth>
        <Results />
      </RequireAuth>
    )
  },
  {
    path: '/results/:sportType/:itemName',
    element: (
      <RequireAuth>
        <ResultsDetail />
      </RequireAuth>
    )
  },
  {
    path: '/match-detail',
    element: (
      <RequireAuth>
        <MatchDetail />
      </RequireAuth>
    )
  },
  {
    path: '/contact',
    element: (
      <RequireAuth>
        <Contact />
      </RequireAuth>
    )
  },
  {
    path: '/profilesettings',
    element: (
      <RequireAuth>
        <ProfileSettings />
      </RequireAuth>
    )
  },
  {
    path: '/changepassword',
    element: (
      <RequireAuth>
        <ChangePassword />
      </RequireAuth>
    )
  },
  {
    path: '/editprofile',
    element: (
      <RequireAuth>
        <EditProfile />
      </RequireAuth>
    )
  },
  {
    path: '/uploaddocument',
    element: (
      <RequireAuth>
        <UploadDocument />
      </RequireAuth>
    )
  },
  {
    path: '/responsiblegaming',
    element: (
      <RequireAuth>
        <ResponsibleGaming />
      </RequireAuth>
    )
  },
  {
    path: '/bet-slip',
    element: (
      <RequireAuth>
        <BetSlipList />
      </RequireAuth>
    )
  },
  {
    path: '/qr-deposit',
    element: (
      <RequireAuth>
        <QRDeposit />
      </RequireAuth>
    )
  },
  {
    path: '/',
    title: 'Home Page',
    element: (
      <RequireAuth>
        <Home />
      </RequireAuth>
    )
  },
  {
    path: '*',
    element: <div>404</div>
  }
];

export default routes;
