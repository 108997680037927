import { useEffect } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';
import './index.scss';

const QRScanner = ({ onResult }) => {
  useEffect(() => {
    const html5QrcodeScanner = new Html5QrcodeScanner('qr-reader', {}, false);
    html5QrcodeScanner.render(
      (decodedText) => {
        onResult(decodedText);
      },
      () => {
        console.log('QR Code scanning initialized.');
      }
    );

    return () => {
      html5QrcodeScanner.clear().catch((error) => {
        console.error('Failed to clear html5QrcodeScanner. ', error);
      });
    };
  }, []);

  return (
    <div className="w-full mx-auto p-4">
      <div id="qr-reader" className="w-full aspect-square bg-gray-100 rounded-lg overflow-hidden" />
    </div>
  );
};

export default QRScanner;
